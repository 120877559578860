<script>
    import Orders from '@/services/Orders';

    export default {
        props: ['guid'],
        data() {
            return {
                showModal: false,
                orderDetails:[]
            };
        },
        methods: {

            async getOrderDetails(){
                await Orders.getOrder(this.guid).then((response) => {
                    this.orderDetails = response.data;
                }).catch(error => {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg(this.error)
                }).finally(() => {
                })
            },

            closeModal() {
                this.showModal = false;
            },
        }
    };
</script>

<template>
    <b-modal @shown="getOrderDetails" v-model="showModal" id="view_order" title="View Customer Order" title-class="font-18" size="xl" centered>
        <div class="row mb-3" v-if="orderDetails.data">

            <div class="col-sm-6">
               <p class="font-size-16 mb-2"><span class="text-strong">Order ID:</span> {{orderDetails.data.id}}</p>
               <p class="font-size-16 mb-2"><span class="text-strong">Customer ID:</span> {{orderDetails.data.customer_id}}</p>
               <p class="font-size-16 mb-2"><span class="text-strong">Paid:</span> {{orderDetails.data.paid}}</p>
               <p class="font-size-16 mb-2"><span class="text-strong">Order Status:</span> <span class="badge bg-pill bg-soft-success font-size-16"
                       :class="{
                                  'bg-soft-danger': orderDetails.data.status === 'cancelled',
                                  'bg-soft-warning': orderDetails.data.status === 'processing',
                                  'bg-soft-danger': orderDetails.data.status === 'failed',
                                }">{{orderDetails.data.status}} </span></p>
            </div>
            <div class="col-sm-6">
                <p class="font-size-16 mb-2"><span class="text-strong">Order Currency:</span> {{orderDetails.data.currency}}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Order Date/Time:</span> {{orderDetails.data.timestamp}}</p>
            </div>

            <hr>

            <div class="row mt-3">
                <div class="col-sm-6">
                    <h5>Order Details</h5>
                    <div class="row">
                        <div class="col-sm-12" v-for="(typeData, typeItem) in orderDetails.data.items" :key="typeItem">
                            <p class="text-strong text-capitalize mb-3">{{typeItem}} Items:</p>
                            <table class="table">
                                <thead>
                                <th>Item</th>
                                <th>Description</th>
                                <th>Price</th>
                                </thead>
                                <tbody>
                                <tr v-for="item in typeData" :key="item.id">
                                    <td>{{item.id}}</td>

                                    <template v-if="typeItem == 'coupon'">
                                        <td>
                                            <p>{{item.coupon}}</p>
                                        </td>
                                    </template>

                                    <template v-else-if="typeItem == 'shipping'">
                                        <td>
                                            <p class="text-capitalize">{{item.service_name}}</p>
                                        </td>
                                    </template>

                                    <template v-else-if="typeItem == 'ticketing'">
                                        <td>
                                            <p class="text-capitalize">Ticket Type: {{item.ticket_type}} Section: {{item.section}} Row: {{item.row}} Seat: {{item.seat}}
                                                <span class="text-strong"> X {{item.qty}}</span></p>
                                        </td>
                                    </template>

                                    <template v-else-if="typeItem == 'voucher'">
                                        <td>
                                            <p class="text-capitalize">{{item.voucher}}</p>
                                        </td>
                                    </template>

                                    <template v-else-if="typeItem == 'merchandise'">
                                        <td>
                                            <p class="text-capitalize">{{item.description}} <span class="text-strong"> X {{item.qty}}</span></p>
                                        </td>
                                    </template>

                                    <template v-else>
                                        <td>

                                        </td>
                                    </template>

                                    <td>{{item.price}}</td>

                                </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>

                </div>
                <div class="col-sm-6">
                    <h5>Payment Details</h5>
                      <div class="row bg-light h-100">
                          <div class="col-sm-12 mt-3 p-3 ">
                              <template v-if="orderDetails.data.totals">
                                  <p class="font-size-16 mb-2"><span class="text-strong">Total Price:</span> {{orderDetails.data.totals.totalPrice}}</p>
                              </template>
                          </div>
                      </div>
                </div>
            </div>


            <div class="row mt-5">
                <hr>
                <div class="col-sm-6">
                    <div class="card">
                        <div class="card-header">
                            <h5>Billing Details</h5>
                        </div>

                        <div class="card-body" v-if="Object.entries(orderDetails.data.billing).length">
                            <div class="col-sm-12">
                                <p class="font-size-16 mb-2"><span class="text-strong">Address: </span> {{orderDetails.data.billing.address_1}} {{orderDetails.data.billing.address_2}}</p>
                                <p class="font-size-16 mb-2"><span class="text-strong">City: </span> {{orderDetails.data.billing.city}} {{orderDetails.data.billing.postcode}}</p>
                                <p class="font-size-16 mb-2"><span class="text-strong">Country: </span> {{orderDetails.data.billing.country}}</p>
                                <p class="font-size-16 mb-2"><span class="text-strong">Email: </span>
                                    <a v-if="orderDetails.data.billing.email" :href="'mailto:'+orderDetails.data.billing.email">{{orderDetails.data.billing.email}}</a>
                                    <a v-else>N/A</a>
                                </p>
                                <p class="font-size-16 mb-2"><span class="text-strong">Phone: </span>
                                    <a v-if="orderDetails.data.billing.phone" :href="'tel:'+orderDetails.data.billing.phone">{{orderDetails.data.billing.phone}}</a>
                                    <a v-else>N/A</a>
                                </p>
                            </div>
                        </div>
                        <div class="row" v-else>
                            <p>Billing data not set</p>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6">
                    <div class="card">
                        <div class="card-header">
                            <h5>Shipping Details</h5>
                        </div>

                        <div class="card-body" v-if="Object.entries(orderDetails.data.shipping).length">
                            <div class="col-sm-12">
                                <p class="font-size-16 mb-2"><span class="text-strong">Address: </span> {{orderDetails.data.shipping.address_1}} {{orderDetails.data.shipping.address_2}}</p>
                                <p class="font-size-16 mb-2"><span class="text-strong">City: </span> {{orderDetails.data.shipping.city}} {{orderDetails.data.shipping.postcode}}</p>
                                <p class="font-size-16 mb-2"><span class="text-strong">Country: </span> {{orderDetails.data.shipping.country}}</p>
                                <p class="font-size-16 mb-2"><span class="text-strong">Email: </span>
                                    <a v-if="orderDetails.data.shipping.email" :href="'mailto:'+orderDetails.data.shipping.email">{{orderDetails.data.shipping.email}}</a>
                                    <a v-else>N/A</a>
                                </p>
                                <p class="font-size-16 mb-2"><span class="text-strong">Phone: </span>
                                    <a v-if="orderDetails.data.shipping.phone" :href="'tel:'+orderDetails.data.shipping.phone">{{orderDetails.data.shipping.phone}}</a>
                                    <a v-else>N/A</a>
                                </p>
                            </div>
                        </div>
                        <div class="row" v-else>
                            <p>Shipping data not set</p>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
        </template>
    </b-modal>
</template>