<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Orders from "@/services/Orders";
    import modalViewOrder from  '@/components/modals/orders/modalViewOrder';
    import modalEditOrder from  '@/components/modals/orders/modalEditOrder';

    /**
     * Orders component
     */
    export default {
        components: { Layout, PageHeader, modalViewOrder, modalEditOrder },
        page: {
            title: "Orders",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                title: "Orders",
                items: [
                    {
                        text: "Orders",
                        active: true,
                    },
                ],
                isBusy: false,
                guid: '',
                tableData: [],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "guid",
                sortDesc: false,
                fields: [
                    {
                        key: "id",
                        label: "Order ID",
                        class:["text-dark","fw-bold"]
                    },
                    {
                        key: "guid",
                        label: "GUI ID",

                    },
                    {
                        key: "customer_id",
                        label: "Customer ID",
                    },
                    {
                        key: "total",
                        label: "Total",
                        sortable: true,
                    },
                    {
                        key: "paid",
                        label: "Paid",
                        sortable: true,
                    },
                    {
                        key: "currency",
                        label: "Currency",
                    },
                    {
                        key: "source",
                        label: "Source",
                    },
                    {
                        key: "timestamp",
                        label: "Date/Time",
                    },
                    {
                        key: "status",
                        label: "Order Status",
                        sortable: true,
                    },
                    "action",
                ],
            };
        },
        middleware: "authentication",
        computed: {

            /**
             * Total no. of records
             */
            rows() {
                return this.tableData.length;
            },
        },
        mounted() {
            // Set the initial number of items
            this.totalRows = this.items.length;
        },
        async created() {
            await this.getOrders()
        },
        methods: {

            async getOrders(){
                try {
                    this.toggleBusy();
                    const response = await Orders.getOrders()
                    this.tableData = response.data.data
                    this.toggleBusy();
                } catch (error) {
                    this.toggleBusy();
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.tableData = []
                }
            },
            modalViewOrder(data){
                this.$bvModal.show("view_order");
                this.guid = data.guid;
            },

            modalEditOrder(data){
                this.$bvModal.show("edit_order");
                this.guid = data.guid;
            },

            /**
             * Search the table data with search input
             */
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
        },
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">

                <div class="table table-centered datatable dt-responsive nowrap table-card-list dataTable no-footer dtr-inline">
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <div id="tickets-table_length" class="dataTables_length">
                                <label class="d-inline-flex align-items-center fw-normal">
                                    Show&nbsp;
                                    <b-form-select
                                            v-model="perPage"
                                            size="sm"
                                            :options="pageOptions"
                                    ></b-form-select
                                    >&nbsp;entries
                                </label>
                            </div>
                        </div>
                        <!-- Search -->
                        <div class="col-sm-12 col-md-6">
                            <div
                                    id="tickets-table_filter"
                                    class="dataTables_filter text-md-end"
                            >
                                <label class="d-inline-flex align-items-center fw-normal">
                                    Search:
                                    <b-form-input
                                            v-model="filter"
                                            type="search"
                                            placeholder="Search..."
                                            class="form-control form-control-sm ms-2"
                                    ></b-form-input>
                                </label>
                            </div>
                        </div>
                        <!-- End search -->
                    </div>
                    <!-- Table -->

                    <b-table
                            table-class="table table-centered datatable table-card-list"
                            thead-tr-class="bg-transparent"
                            :busy="isBusy"
                            :items="tableData"
                            :fields="fields"
                            responsive="sm"
                            :per-page="perPage"
                            :current-page="currentPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :filter="filter"
                            :filter-included-fields="filterOn"
                            sort-icon-left
                            @filtered="onFiltered"
                            show-empty
                            empty-text="No Data Found"
                    >
                        <template #table-busy>
                            <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong>Loading...</strong>
                            </div>
                        </template>

                        <template v-slot:cell(status)="data">
                            <div
                                    class="badge bg-pill bg-soft-success font-size-12 text-capitalize"
                                    :class="{
                                      'bg-soft-danger': data.item.status === 'cancelled',
                                      'bg-soft-warning': data.item.status === 'processing',
                                      'bg-soft-danger': data.item.status === 'failed',
                                    }"
                            >
                                {{ data.item.status }}
                            </div>
                        </template>
                        <template v-slot:cell(action)="data">
                            <ul class="list-inline mb-0">
                                <li class="list-inline-item">
                                    <a
                                            href="javascript:void(0);"
                                            @click="modalViewOrder(data.item)"
                                            class="px-2 text-primary"
                                            v-b-tooltip.hover
                                            title="View Order"
                                    >
                                        <i class="uil uil-eye font-size-18"></i>
                                    </a>
                                </li>
                                <li class="list-inline-item">
                                    <a
                                            href="javascript:void(0);"
                                            @click="modalEditOrder(data.item)"
                                            class="px-2 text-primary"
                                            v-b-tooltip.hover
                                            title="Edit Order"
                                    >
                                        <i class="uil uil-pen font-size-18"></i>
                                    </a>
                                </li>
                            </ul>
                        </template>
                    </b-table>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="dataTables_paginate paging_simple_numbers float-end">
                            <ul class="pagination pagination-rounded">
                                <!-- pagination -->
                                <b-pagination
                                        v-model="currentPage"
                                        :total-rows="rows"
                                        :per-page="perPage"
                                ></b-pagination>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODALS -->
        <modalViewOrder :guid="this.guid"></modalViewOrder>
        <modalEditOrder :guid="this.guid"  @onRefresh="getOrders()"></modalEditOrder>
        <!-- /MODALS -->

    </Layout>
</template>
